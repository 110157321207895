@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --main-100: #01426A;
    --main-50: #7d7aa1;
    --main-10: #4DBBC6;
    --main-orange-100: #f77b42;
    --main-orange-50: #fab08e;
    --main-orange-10: #fef2ec;
}

body {
    font-family: 'Inter', sans-serif;
    font-size:16px;
    line-height:1.4em;
    overflow-y: auto;
    overflow-x: hidden;
    background:transparent;
    color:#01426A;
    color:var(--main-100);
}

h1,h2,h3,h4,h5,h6 {
    font-weight: 900;
}
.container {
    max-width: none;
}

.navbar {
    background-color:#01426A;
}

.navbar-toggler {
    background-color:#ffff !important;
}
.nav-link {
    color:#ffff !important;
}

.active {
    font-weight: 900 !important;
    color: #f77b42 !important;
    color: var(--main-orange-100) !important;
}

.contact {
    background:rgba(212, 211, 224,0.2);;
}

.hero {
    background:rgba(212, 211, 224,0.2);
    height: 400px;
}

.hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 780px;
    opacity: .2;
    z-index: -1;
    background: url(/static/media/background.216d5db2.PNG) repeat;
    background-size: cover;
}

.clients {
    background:rgba(212, 211, 224,0.2);;
    padding: 50px 50px;
    overflow-x: hidden;
    overflow-y: hidden;
    /*white-space: nowrap;*/
    margin-bottom:40px;
}

.client-item {
    padding: 0 20px 0 0;
    font-weight:900;
    font-size: 1.5em;
    opacity: 0.5;
    line-height:2em;
    white-space: nowrap;
}

.background{
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: -1;
    opacity:0.4;
}

.card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    border-radius: 10px;
}

.card-title {
    font-weight: 900;
}

.btn {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.btn-outline-primary {
    color: #01426A;
    color: var(--main-100);
    border-color: #01426A;
    border-color: var(--main-100);
}

.btn-outline-primary:hover,.btn-outline-primary:active,.btn-outline-primary:focus {
    color: #01426A;
    color: var(--main-100);
    border-color: #01426A;
    border-color: var(--main-100);
    background:#4DBBC6;
    background:var(--main-10);
}

.badge {
    background: #4DBBC6 !important;
    background: var(--main-10) !important;
    color: #01426A !important;
    color: var(--main-100) !important;
}

.projects .badge {
    margin-right:5px;
}

.text-warning {
    font-size:0.6em;
    color:#fab08e !important;
    color:var(--main-orange-50) !important;
}

